import React from "react"
import { Link, graphql } from "gatsby"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useColorMode } from "theme-ui"
const Blog = ({ data, location }) => {
  const [colorMode] = useColorMode()
  const posts = data.allMarkdownRemark.edges
  console.info({data})
  return (
    <Layout location={location} title={"Blog"}>
      <Bio colorMode={colorMode} />
      <h1>Blog</h1>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <article key={node.fields.slug}>
           <SEO title={title} description={node.frontmatter.description} lang="en"/>
            <ins
              className="adsbygoogle"
              style={{ display: "block", textAlign: "center" }}
              data-ad-layout="in-article"
              data-ad-format="fluid"
              data-ad-client="ca-pub-6737096029620039"
              data-ad-slot={`5489196409/${title}`}
            ></ins>
            <header>
              <h3
                style={{
                  marginBottom: "2rem",
                }}
              >
                <Link
                  style={{
                    boxShadow: `none`,
                    color: colorMode === "dark" ? "#37e8ff" : "#E91E63",
                  }}
                  to={node.fields.slug}
                >
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/(blog)/" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
